<template>
  <div>
    <v-row dense>
      <v-col cols="3">
        <select-warehouse-model
          v-model="id_warehouse"
          :label="$t('labels.warehouse')"
          dense
          outlined
          clearable
          hide-details
          @change="onWarehouseUpdate"
        ></select-warehouse-model>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="stampSize"
          :items="sizeStampOptions"
          :label="$t('labels.stamp_size')"
          dense
          outlined
          class="c-input-small"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :label="$t('labels.uid')"
          @keyupEnter="inputScan"
          :auto-close="false"
        ></input-qr-scan-model>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { downloadExcelFile } from "@/libs/helpers";

export default {
  name: "RePrintStamp",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    id_warehouse: null,
    stampSize: "50x20",
    sizeStampOptions: [
      {
        text: "50x20",
        value: "50x20",
      },
      {
        text: "50x40",
        value: "50x40",
      },
    ],
  }),
  computed: {},
  mounted() {},
  methods: {
    downloadExcelFile,
    onWarehouseUpdate() {
      if (!this.id_warehouse) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput();
      });
    },
    async inputScan() {
      if (!this.code || !this.code.includes("@")) {
        this.$vToastify.error(this.$t("messages.not_ssc_stamp"));
        this.$root.$emit("playErrorAudio");
      }
      const stampName = `${this.stampSize}_${this.code}.pdf`;
      await this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-stamp-again`,
        {
          code: this.code,
          id_warehouse: this.id_warehouse,
          stamp_size: this.stampSize,
        },
        stampName
      );
      this.code = null;
    },
  },
};
</script>

<style scoped></style>
